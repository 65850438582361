import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import RichText from "../Prismic/RichText";
import { Row } from "src/client/js/view/components/Grid";
import "./Description.scss";

const size = {
  "80%": "4-5",
  "75%": "3-4",
};

const Description = (props) => (
  <section
    className={cx("description", {
      [props.className]: props.className,
    })}
  >
    <div className="container-guttered">
      <Row>
        <div
          className={cx("col", {
            [`col-${size[props.primary.size]}`]: size[props.primary.size],
          })}
        >
          <RichText fields={props.primary.content} />
        </div>
      </Row>
    </div>
  </section>
);

Description.propTypes = {
  primary: PropTypes.shape({
    size: PropTypes.string,
    content: PropTypes.array.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default Description;
